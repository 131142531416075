<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/trocarsenha.css">

    <header class="card-header main-header">
      <div class="row h-100">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-12">
              <h2 class="card-title text-center">
                <span class="selecionado">Trocar senha de acesso</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="row main-row">
      <div class="col">
        <div class="card-body">
          <form id="form">
            <div class="row form-group" v-if="!success">
              <div class="col-lg-12">
                <div class="validation-message" v-if="$root.errors && $root.errors.length">
                  <ul style="display: block;">
                    <li v-for="p in $root.errors" :key="p">
                      <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="col-form-label">Senha atual</label>
                  <input type="password" class="form-control form-control-sm" v-model="obj.SenhaAtual" />

                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Nova senha</label>
                    <input type="password" class="form-control form-control-sm" v-model="obj.NovaSenha" />

                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="col-form-label">Confirmação nova senha</label>
                  <input type="password" class="form-control form-control-sm" v-model="obj.ConfirmacaoNovaSenha" />

                </div>
              </div>

              <div class="col-12">
                <div class="row mt-3">
                  <div class="col-5">
                    <a href="/" class="btn btn-block btn-sm btn-danger">Cancelar</a>
                  </div>
                  <div class="col-2">

                  </div>
                  <div class="col-5">
                    <button id="entrar" type="button" @click="trocar" class="btn btn-block btn-sm btn-primary">Trocar</button>
                  </div>
                  <div class="col-12">
                    <p class="mt-3"><i>Sua nova senha deverá ter no mínimo 6 caracteres.</i></p>
                  </div>
                </div>
              </div>
            </div>
            <h3 v-if="success" class="text-center">Senha alterada com sucesso!</h3>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';

//trocar senha
export default {

    components: {
    },
    data() {
        return {
            obj: {
                SenhaAtual: '',
                NovaSenha: '',
                ConfirmacaoNovaSenha: ''
            },
            success: false
        };
    },
    methods: {
        trocar: function () {
            debugger;
            let that = this;
            this.$root.errors = [];
            if (this.obj.NovaSenha.length < 6) {
                this.$root.errors.push('A senha deve ter no mínimo 6 caracteres.');
            }
            else if (this.obj.NovaSenha !== this.obj.ConfirmacaoNovaSenha) {
                this.$root.errors.push('O campo confirmação de senha deve ser igual ao nova senha.');
            }

            !this.$root.errors.length &&
            api.post('/configuracoes/trocarsenha',this.obj)
                .then(response => {
                    that.success = response.data.success;
                });
        }
    },
    beforeMount: function () {

    }
};
</script>

<style scoped>
  .card .card-body {
    overflow: auto;
}

.card {
    height: auto;
    min-height: 100%
}

#form {
    max-width: 400px;
    margin: 0 auto;
}
.validation-message ul{
    margin: 0;
}


</style>
